import React from 'react'
import Layout from '../components/layout'
import {graphql, useStaticQuery} from 'gatsby'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import styles from './faq.module.scss'
import SEO from '../components/seo'

const FAQ = () => {

    const data = useStaticQuery(graphql`
        query{
            contentfulFaq(title: {eq:"FAQ"}){
            title
            content{
                json
            }
            }
        }
    `)

    const options= {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    }

    return (
        <Layout>
            <SEO title="FAQ" keywords={[ `reflect`, `simple things`, `bullet journal`, `stationary`, `notebooks`, `journal`, `planner`, `bullet journal setup`]}/>
            <div className={styles.container}>
                {documentToReactComponents(data.contentfulFaq.content.json, options)}
            </div>
        </Layout>
    )
}

export default FAQ;